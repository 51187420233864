// ** React
import React from 'react';

// ** Gatsby
import { graphql } from 'gatsby';

// ** Layout
import Layout from '../containers/layout';

// ** Custom Components
import Container from '../components/layout/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/layout/seo';
import SectionCourse from '../components/pagebuilder/section-course';

export const query = graphql`
  query CourseTemplateQuery($id: String!) {
    site {
      meta: siteMetadata {
        siteUrl
      }
    }
    page: sanityCourse(id: { eq: $id }) {
      ...Course
    }
    siteSetting: sanitySiteSettings {
      fallbackImage {
        _key
        alt
        image {
          asset {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

const CourseTemplate = (props) => {
  const {
    data: { page, siteSetting },
    errors,
  } = props;

  const { title } = page;

  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {title && <SEO title={title} />}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {page && <SectionCourse section={page} siteSetting={siteSetting} />}
    </Layout>
  );
};

export default CourseTemplate;
